


























import { Component, Vue } from 'vue-property-decorator';
import IVariant from '@/interfaces/IVariant';

import getUserContactViaSettings from '@/graphql/me/account/UserContactViaSettings.query.gql';
import updateUserContactViaSettings from '@/graphql/me/account/UpdateUserContactSettings.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import Checkbox from '@/components/partials/elements/Checkbox.vue';
import ContactPreferencesSettings from '@/components/partials/profile/ContactPreferencesSettings.vue';

@Component({
  components: {
    BackHeader,
    Checkbox,
    ContactPreferencesSettings,
  },
  metaInfo: {
    title: 'Contact preferences',
  },
  apollo: {
    ContactVia: {
      query: getUserContactViaSettings,
      result(result: any) {
        this.contactBy = result.data.ContactVia.contactVia;
      },
    },
  },
})
export default class ContactPreferences extends Vue {
  contactBy: any = [];
  dataArr: any = [];

  changeContactWays(way: IVariant) {
    this.dataArr = [];
    this.contactBy.forEach((value: any) => {
      if (value.id == way.id) {
        value.selected = way.selected;
      }
      if (value.selected) {
        this.dataArr.push(value.id);
      }
    });

    this.$apollo.mutate({
      mutation: updateUserContactViaSettings,
      variables: {
        id: this.dataArr,
      },
    });
  }
}
